import React from "react";
import ReactDOM from "react-dom/client";
import { Helmet } from "react-helmet";

import { RouterProvider } from "react-router-dom";
import { CookiesProvider } from "react-cookie";

import { router } from "./route.js";
import { AuthProvider } from "./context/AuthContext";
import { SocketProvider } from "./context/SocketContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
const host = 'https://womansmell.ru/'
root.render(
  <React.StrictMode>
    <Helmet>
      <title>Купить и продать грязные трусики на фетиш-маркете</title>
    </Helmet>
    <CookiesProvider>
      <AuthProvider>
        <SocketProvider>
          <RouterProvider router={router} />
        </SocketProvider>
      </AuthProvider>
    </CookiesProvider>
  </React.StrictMode>,
);
