/*eslint no-mixed-operators: ["error", {"groups": [["&&", "||", "?:", ]]}]*/

import validator from 'validator';
import { Store } from "react-notifications-component";

const IS_SECURE = document.location.protocol === 'https:'
const IS_DEV = process.env.NODE_ENV === 'development'
const BACKEND_DOMAIN = IS_DEV ? 'localhost:88': 'womansmell.ru'
// const BACKEND_DOMAIN = 'localhost:88'

const HTTP_HOST = `${document.location.protocol}//${BACKEND_DOMAIN}`
const WS_HOST = `${IS_SECURE ? 'wss': 'ws'}://${BACKEND_DOMAIN}` 
// const WS_HOST = `ws://localhost:5000` 

const graphqlURL = `${HTTP_HOST}/core/graphql/`;
const wsURL = `${WS_HOST}/ws`;
const shopFavoriteName = 'shopFavorite'

function getRowGalleryClassNames(index) {
  let className = "col-xl-4 col-lg-6 grid-item col-md-6 ";
  switch (index % 7) {
    case "0":
      className = "col-xl-5 col-lg-5 grid-item col-md-6";
      break;
    case "1":
      className = "col-xl-7 col-lg-7 grid-item col-md-6";
      break;
    case "5":
      className = "col-xl-5 col-lg-5 grid-item col-md-6";
      break;
    default:
      className = "col-xl-7 col-lg-7 grid-item col-md-6";
  }
  return className;
}

function toDateFormat(strDate) {
  let strSplitDate = String(strDate).split(" ")[0].split("T");
  let date = new Date(strSplitDate[0]);
  let dd = date.getDate();
  let mm = date.getMonth() + 1; //January is 0!
  let yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return `${dd}.${mm}.${yyyy}`;
}

function strDateTimeToLocal(strDate) {
  let ltzDate = (new Date(strDate)).toLocaleString();
  return ltzDate
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (readerEvt) => {
      let ext = file.type.split('/')[1] 
      let file_name = file.name || `avatar.${file.type.split('/')[1]}`
      resolve(reader.result.replace("base64", file_name));
    };
    reader.onerror = (error) => reject(error);
  });
}
const getBase64List = async function(files) {
  let promises = []
  for(let i = 0; i < files.length; i++) {
    promises.push(getBase64(files[i]))
  }
  return await Promise.all(promises)
}


function getCookie(name, defaultValue) {
  // eslint-disable-next-line
  const reg = new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)")
  let matches = document.cookie.match(reg);
  return matches ? decodeURIComponent(matches[1]) : defaultValue;
}

function setCookie(name, value, options = {}, path = '/') {

  options = {
    path: path,
    ...options
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}


function setOpenchatCookien(shopSlug, msg){
   let date = new Date();
   date.setTime(date.getTime() + (5 * 1000));
   setCookie(`openchat_${shopSlug}`, msg, {expires: date})
}

function deleteCookie(name) {
  setCookie(name, "", {
    'max-age': -1
  })
}

const tokenName = "token";
const useruidName = "userUid";

function getToken() {
  return getCookie(tokenName, null);
}

function uuidv4() {
    return (
      [1e7]+-1e3+-4e3+-8e3+-1e11).replace( // eslint-disable-line no-use-before-define
        /[018]/g, c => ( // eslint-disable-line no-use-before-define
          c ^ crypto.getRandomValues( // eslint-disable-line no-use-before-define
            new Uint8Array(1))[0] & 15 >> c / 4 // eslint-disable-line no-use-before-define
        ).toString(16)
  );
}

function makeUserUid() {
  let userUid = getCookie(useruidName, '');
  if(!validator.isUUID(userUid)){
    userUid = uuidv4();
    setUserUid(userUid)
  }
  return userUid
}

function setToken(value) {
  setCookie(tokenName, value);
}
function setUserUid(value) {
  setCookie(useruidName, value);
}

function removeToken() {
  deleteCookie(tokenName);
}

function logoutUser() {
  deleteCookie(tokenName);
  deleteCookie(useruidName);
  deleteCookie('csrftoken');
  deleteCookie('sessionid');
}

function getShopChatCookieName(shopSlug){
  return `chat_${shopSlug}`
}

function getShopChatId(shopSlug){
  return getCookie(getShopChatCookieName(shopSlug), '');
}

function setShopChatId(shopSlug, chatId){
  setCookie(getShopChatCookieName(shopSlug), chatId);
}
  
function getSocket(userUid) {
  // return new WebSocket(`ws://localhost:5000/ws/${userUid}`);
  return new WebSocket(`${wsURL}/${userUid}`);
}

function createMarkup(valueHtml) {
  return { __html: valueHtml };
}
function showNotify(title, msg, msgType) {
  // success
  // danger
  // info
  // default
  // warning
  return Store.addNotification({
    title: title,
    message: msg || '',
    type: msgType || "info",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
}

const getHeightAndWidthFromDataUrl = dataURL => new Promise(resolve => {
    const img = new Image()
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width
      })
    }
    img.src = dataURL
  })

function pluralize(count, words) {
    let cases = [2, 0, 1, 1, 1, 2];
    return count + ' ' + words[ (count % 100 > 4 && count % 100 < 20) ? 2 : cases[ Math.min(count % 10, 5)] ];
}

function getUrlsFromText(text) {
  const reg = new RegExp(
          "(^|[ \t\r\n])((ftp|http|https|gopher|mailto|news|nntp|telnet|wais|file|prospero|aim|webcal):(([A-Za-z0-9$_.+!*(),;/?:@&~=-])|%[A-Fa-f0-9]{2}){2,}(#([a-zA-Z0-9][a-zA-Z0-9$_.+!*(),;/?:@&~=%-]*))?([A-Za-z0-9$_+!*();/?:~-]))"
         ,"g"
       );

  return Array(text.match(reg));
}

function getShopFavoriteList(){
  let favorites = getCookie(shopFavoriteName)
  return favorites ? favorites.split(',') : []
}
function delFromFavorite(shopSlug){
  let shopFavoriteList = getShopFavoriteList()
  shopFavoriteList = shopFavoriteList.filter(item => item !== shopSlug)
  setCookie(shopFavoriteName, shopFavoriteList)
  return shopFavoriteList
}

function addToFavorite(shopSlug){
  let shopFavoriteList = getShopFavoriteList()
  shopFavoriteList.push(shopSlug)
  setCookie(shopFavoriteName, [...new Set(shopFavoriteList)])
  return shopFavoriteList
}


export {
  graphqlURL,
  getRowGalleryClassNames,
  toDateFormat,
  strDateTimeToLocal,
  getBase64,
  getBase64List,
  getCookie,
  setCookie,
  deleteCookie,
  getToken,
  setToken,
  removeToken,
  logoutUser,
  setUserUid,
  makeUserUid,
  getShopChatId,
  setShopChatId,
  getSocket,
  createMarkup,
  showNotify,
  getHeightAndWidthFromDataUrl,
  pluralize,
  getUrlsFromText,
  setOpenchatCookien,
  getShopFavoriteList,
  addToFavorite,
  delFromFavorite,
};
