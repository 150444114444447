import React from "react";

import { createBrowserRouter } from "react-router-dom";
import loadable from "@loadable/component";

const Home = loadable(() => import("./pages/Home"));
const Shops = loadable(() => import("./pages/Shops"));
const Shop = loadable(() => import("./pages/Shop"));
const Catalog = loadable(() => import("./pages/Catalog"));
const Gallery = loadable(() => import("./pages/Gallery"));
const Favorite = loadable(() => import("./pages/Favorite"));
const Offer = loadable(() => import("./pages/Offer"));
const ShopOrder = loadable(() => import("./pages/ShopOrder"));
const TextPage = loadable(() => import("./pages/TextPage"));
const Me = loadable(() => import("./pages/Me"));
const MeSettings = loadable(() => import("./pages/me/Settings"));
const MeChats = loadable(() => import("./pages/me/Chats"));
const MeOrders = loadable(() => import("./pages/me/Orders"));
const ErrorPage404 = loadable(() => import("./pages/ErrorPage404"));
const AboutUs = loadable(() => import("./pages/AboutUs"));
const Feed = loadable(() => import("./pages/Feed"));
const Product = loadable(() => import("./pages/Product"));

// admin
const ManageProfile = loadable(() => import("./pages/admin/ManageProfile"));
const Stat = loadable(() => import("./pages/admin/Stat"));
const Chat = loadable(() => import("./pages/admin/Chat"));
const ContentGallery = loadable(() => import("./pages/admin/ContentGallery"));
const Offers = loadable(() => import("./pages/admin/Offers"));
const Orders = loadable(() => import("./pages/admin/Orders"));

function makeQuery(operationName, query, variables) {
  return {
    operationName: operationName,
    query: query,
    variables: variables || {},
  };
}

const routes = [
  { path: "/", element: <Home /> },
  { path: "/shops", element: <Shops /> },
  { path: "/catalog", element: <Catalog /> },
  { path: "/gallery", element: <Gallery /> },
  { path: "/feed", element: <Feed /> },
  { path: "/favorite", element: <Favorite /> },
  {
    path: "/me",
    element: <Me />,
    children: [
      { path: "settings", element: <MeSettings />, index: true },
      { path: "orders", element: <MeOrders /> },
      { path: "chats", element: <MeChats /> },
    ],
  },
  { path: "/aboutus", element: <AboutUs /> },
  { path: "/faq", element: <AboutUs />, forceRefresh: true },
  { path: "/page/:pageSlug", element: <TextPage /> },
  // { path: "/shop/:shopSlug", element: <Shop /> },
  // { path: "/shop/:shopSlug/order/:orderId", element: <ShopOrder /> },
  // { path: "/shop/:shopSlug/:offerId", element: <Offer /> },
  { path: "/shops/:shopSlug", element: <Shop /> },
  { path: "/shops/:shopSlug/:offerId", element: <Offer /> },
  { path: "/shops/:shopSlug/products/:productSlug", element: <Product /> },
  { path: "/shops/:shopSlug/order/:orderId", element: <ShopOrder /> },
  //
  { path: "/admin/profile", element: <ManageProfile /> },
  { path: "/admin/stat", element: <Stat /> },
  { path: "/admin/chat", element: <Chat /> },
  { path: "/admin/chat/:chatId", element: <Chat /> },
  { path: "/admin/gallery", element: <ContentGallery /> },
  { path: "/admin/offers", element: <Offers /> },
  { path: "/admin/orders", element: <Orders /> },
  // { path: "/admin/shop/profile", element: lazyLoadRoutes('ManageProfile.jsx')},
];

const router = createBrowserRouter(routes);

export { makeQuery, router, routes };
