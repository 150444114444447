import React from "react";

import { getSocket } from "../utils.js";
import { useAuthState } from "./AuthContext"


const socket = null;


const initialState = {
  connect: false,
  socket: socket,
  errors: [],
  socketMsg: null,
};

const SocketStateContext = React.createContext();
const SocketDispatchContext = React.createContext();


function reducer(currentState, newState) {
  return { ...currentState, ...newState };
}

function useSocketState() {
  const context = React.useContext(SocketStateContext);
  if (!context) throw new Error("useSocketState must be used in SocketProvider");
  return context;
}

function useSocketDispatch() {
  const context = React.useContext(SocketDispatchContext);
  if (!context) throw new Error("useSocketDispatch must be used in SocketProvider");
  return context;
}

function cleanMsg(dispatch){
      dispatch({socketMsg: null})      
}

function SocketProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [wsOpen, setWsOpen] = React.useState(false);
  const [countConnect, setCountConnect] = React.useState(0);
  const [ws, setWs] = React.useState(state.socket);
  const { userUid } = useAuthState();

  function initSocket(socket) {
    setWs(socket)

    socket.onopen = () => {
      setWsOpen(true);
      dispatch({socket: socket, connect: true})      
    };

    socket.onmessage = function (event) {
      const msgData = JSON.parse(event.data);
      dispatch({socketMsg: msgData})      
      setTimeout(() => {
        dispatch({socketMsg: null})      
      }, 100)      
    };

    socket.onclose = function (event) {
      setWsOpen(false)
      dispatch({socket: null, connect: false})      
    };

    socket.onerror = function (error) {
      setWsOpen(false)
      dispatch({socket: null, connect: false})      
    };
  }

  function reInitSocket(){
		if(ws) {
		  ws.close()
		  setWs(null)
		}
		setCountConnect(countConnect + 1)
    if (countConnect < 5) {
		  initSocket(getSocket(userUid))
    }
	}

  React.useEffect(() => {
    if(!wsOpen) {
      reInitSocket()
    }
  }, [wsOpen])
  React.useEffect(() => {
      reInitSocket()
  }, [userUid])

  // console.log(state, dispatch, '-------------------')
  return (
    <SocketStateContext.Provider value={state}>
      <SocketDispatchContext.Provider value={dispatch}>
        {props.children}
      </SocketDispatchContext.Provider>
    </SocketStateContext.Provider>
  );
}

export {
  SocketProvider,
  useSocketState,
  useSocketDispatch,
};
