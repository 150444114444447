import React from "react";
import axios from "axios";

import { getCookie, setCookie, setToken, getToken, graphqlURL, makeUserUid, setUserUid } from "../utils.js";

const initialStateUser = null;

const confirm18name = "age18confirm";
const confirm18vlaue = String(getCookie(confirm18name)) === "true";
// const confirm18vlaue = true;
const token = getToken();
const userUid = makeUserUid();

if (token) {
  axios.defaults.headers.common["Authorization"] = `JWT ${token}`;
}
const initialState = {
  isAuth: false,
  confirm18: confirm18vlaue,
  status: null,
  user: initialStateUser,
  errors: [],
  token: token,
  userUid: userUid,
  isSeller: false,
  isLoginModal: false,
  loginModalType: null,
};

const AuthStateContext = React.createContext();
const AuthDispatchContext = React.createContext();

function reducer(currentState, newState) {
  return { ...currentState, ...newState };
}

function useAuthState() {
  const context = React.useContext(AuthStateContext);
  if (!context) throw new Error("useAuthState must be used in AuthProvider");
  return context;
}

function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext);
  if (!context) throw new Error("useAuthDispatch must be used in AuthProvider");
  return context;
}

async function authToken() {
  try {
    let result = await getUser();
    initialState["token"] = token;
    initialState["isAuth"] = true;
    initialState["confirm18"] = true;
    initialState["user"] = result.data.data.me;
    initialState["userUid"] = result.data.data.me.id;
    initialState["isSeller"] = result.data.data.me.isSeller;
  } catch (error) {
    console.log("authToken--------", error);
  }
  return initialState;
}
if (token) {
  authToken();
}

function getUser(state, dispatch) {
  return axios.post(graphqlURL, {
    query: `{me { id, email, fullName, avatar, bigAvatar, isSeller }}`,
    variables: null,
  });
}

function tokenAuth(email, password) {
  return axios.post(graphqlURL, {
    query: `mutation{
        tokenAuth(email: "${email}", password: "${password}") {
          token,
          user {
            email, fullName, avatar, bigAvatar, isSeller, id
          }
        }
      }`,
  }).catch((err) => {
    console.log('catch((err)', err);
  });
}

function confirmCodeAuth(userUid, code, email) {
  return axios.post(graphqlURL, {
    query: `mutation{
        codeAuth(userUid: "${userUid}", email: "${email}", code: "${code}") {
          token,
          user {
            email, fullName, avatar, bigAvatar, isSeller
          }
        }
      }`,
  })
}

function AuthProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {props.children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}

function setUserData(token, user, callDispatch) {
  if (token) {
    setToken(token);
    axios.defaults.headers.common["Authorization"] = `JWT ${token}`;
  }
  setUserUid(user.id)
  callDispatch({
    status: "resolved",
    isAuth: true,
    isSeller: !!user.isSeller,
    user: user,
    userUid: user.id,
    token: token,
    errors: [],
  });
}
function setUserConfirm18(confirm18Value, callDispatch) {
  callDispatch({confirm18: !!confirm18Value});
  setCookie(confirm18name, String(!!confirm18Value))
}

function setUserErr(errors, dispatch) {
  dispatch({ status: "rejected", errors, token: null, isAuth: false });
}

function setIsLoginModal(dispatch, value) {
  dispatch({ isLoginModal: !!value});
}

function setLoginModalType(dispatch, value) {
  dispatch({ loginModalType: value, isLoginModal: !!value});
}

async function doLogin(dispatch, email, password, afterLogin) {
  try {
    dispatch({ status: "pending" });
    const result = await tokenAuth(email, password);
    console.log(result)
    let dataToken = result.data.data.tokenAuth;
    let dataErrors = result.data.errors;
    if (dataToken) {
      setUserData(dataToken.token, dataToken.user, dispatch);
      if(afterLogin) {
        afterLogin(dataToken)
      }
    }
    if(dataErrors){
      setUserErr(dataErrors, dispatch);
    }

  } catch (error) {
    console.log(error)
    setUserErr(error, dispatch);
  }
}
async function doConfirmCode(dispatch, userUid, email, code, afterLogin) {
  try {
    dispatch({ status: "pending" });
    const result = await confirmCodeAuth(userUid, email, code);
    let dataToken = result.data.data.codeAuth;
    let dataErrors = result.data.errors;
    if (dataToken) {
      setUserData(dataToken.token, dataToken.user, dispatch);
      if(afterLogin) {
        afterLogin(dataToken)
      }
    }
    if(dataErrors){
      setUserErr(dataErrors, dispatch);
    }

  } catch (error) {
    setUserErr(error, dispatch);
  }
}

export {
  AuthProvider,
  useAuthState,
  useAuthDispatch,
  setUserConfirm18,
  doLogin,
  initialState,
  initialStateUser,
  doConfirmCode,
  setIsLoginModal,
  setLoginModalType,
};
